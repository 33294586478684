/*-------------------------------------- TITRE ET TEXTE --------------------------------------*/

h1 {
  font-family: $font-Enchanted;
  font-size: 50px;
  line-height: 85%;
  color: $colorQuinary;
  text-align: center;
  @include screen-576 {
    font-size: 60px;
  }
  @include screen-768 {
    font-size: 70px;
  }
  @include screen-1200 {
    font-size: 75px;
  }
  @include screen-1400 {
    font-size: 80px;
  }
  @include screen-1800 {
    font-size: 95px;
  }
}

h2 {
  font-family: $font-Enchanted;
  font-size: 35px;
  margin-bottom: 5%;
  line-height: 90%;
  color: $colorQuinary;
  text-align: center;
  @include screen-576 {
    font-size: 45px;
  }
  @include screen-768 {
    font-size: 55px;
  }
  @include screen-1200 {
    font-size: 60px;
  }
  @include screen-1400 {
    font-size: 70px;
  }
  @include screen-1800 {
    font-size: 80px;
  }
}

h3,
.h3 {
  font-family: $font-Enchanted;
  font-size: 40px;
  margin: 10% 0 5% 0;
  line-height: 100%;
  color: $colorQuinary;
  text-align: center;
  @include screen-768 {
    font-size: 50px;
  }
  @include screen-1200 {
    font-size: 55px;
  }
  @include screen-1400 {
    font-size: 60px;
  }
  @include screen-1800 {
    font-size: 65px;
  }
}

h4 {
  font-family: $font-Enchanted;
  font-size: 25px;
  padding-bottom: 2%;
  @include screen-768 {
    font-size: 40px;
  }
  @include screen-1200 {
    font-size: 45px;
  }
  @include screen-1800 {
    font-size: 50px;
  }
}

h5 {
  font-size: 15px;
  @include screen-576 {
    font-size: 25px;
  }
  @include screen-768 {
    font-size: 30px;
  }
  @include screen-1200 {
    font-size: 35px;
  }
  @include screen-1400 {
    font-size: 30px;
  }
  @include screen-1800 {
    font-size: 30px;
  }
}

p,
li {
  text-align: center;
  font-size: 1rem;
  line-height: 130%;
  @include screen-768 {
    font-size: 1.1rem;
  }
  @include screen-1200 {
    font-size: 1.25rem;
  }
  @include screen-1800 {
    font-size: 1.45rem;
  }
}

.titre_page > h1 {
  text-shadow: 2px 2px 5px $colorQuinary;
}

h1 > span {
  color: $colorTertiary;
}

h2 > span,
h3 > span,
.presentation_before > div > h1 > span {
  color: $colorQuaternary;
}

.titre_page h1 {
  z-index: 10;
  display: block;
  margin: 0 auto;
  color: $colorSecondaryBis;
  font-size: 40px;
  @include screen-576 {
    font-size: 60px;
  }
  @include screen-1200 {
    font-size: 95px;
  }
}

.titre_page {
  position: absolute;
  width: 100%;
  @include screen-768 {
    font-size: 70px;
  }
}

.texte_clair {
  color: $colorSecondary;
}

.btn {
  padding: 0;
}
