/*-------------------------------------- AVIS CLIENTS... --------------------------------------*/

.avis_clients {
  background-color: $colorTertiary;
}

.avis-titre {
  color: $colorQuaternaryBis;
}

.avis_clients_row {
  padding-top: 5%;
  padding-bottom: 5%;
}

.carousel-indicators {
  display: none;
}

.texte_carousel-small {
  font-family: "AppleGaramond-light-Italic", serif;
  font-size: 0.7rem;
  color: $colorQuinary;
  padding: 0 30px;
  margin-bottom: 15px;
  margin-top: 15px;
  @include screen-576 {
    font-size: 1rem;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  @include screen-768 {
    font-size: 1.1rem;
    margin-bottom: 32px;
    margin-top: 32px;
  }
  @include screen-992 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  @include screen-1200 {
    font-size: 1.25rem;
  }
  @include screen-1800 {
    font-size: 1.55rem;
  }
}

.texte_carousel {
  font-family: "AppleGaramond-light-Italic", serif;
  font-size: 0.7rem;
  color: $colorQuinary;
  @include screen-576 {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 1rem;
  }
  @include screen-768 {
    font-size: 1.1rem;
  }
  @include screen-1200 {
    font-size: 1.25rem;
  }
  @include screen-1800 {
    font-size: 1.55rem;
  }
}

.slide {
  margin-top: 5%;
}
