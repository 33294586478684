.texte_theme {
  padding: 0 10% 0rem 10%;
  @include screen-768 {
    padding-bottom: 3rem;
  }
}

.col-projet {
  padding: 0 20px 0 0;
}

.trois_projets {
  @include screen-768 {
    padding: 0 13%;
  }
}

.projet {
  margin-top: 15%;
  @include screen-768 {
    margin-top: 5%;
  }
}

/*-------------------------------------- EFFET PROJET --------------------------------------*/

figure {
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  width: 100%;
  background: #000000;
  text-align: center;
  border-radius: 10px;
  vertical-align: middle;
  @include screen-992 {
    font-size: 20px;
    line-height: 120%;
  }
}

figure * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

figure h2 {
  @include screen-768 {
    font-size: 15px;
    line-height: 120%;
  }
  @include screen-1200 {
    font-size: 30px;
    line-height: 120%;
  }
}

figure img {
  opacity: 1;
  width: 100%;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

.photo_projet {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  p {
    font-size: 20px;
    font-style: italic;
    margin-top: 5%;
    margin-bottom: 0;
    @include screen-768 {
      font-size: 10px;
    }
    @include screen-992 {
      font-size: 12px;
    }
    @include screen-1400 {
      font-size: 15px;
    }
  }
}

figure .photo_projet::before {
  position: absolute;
  top: 15%;
  right: 20px;
  bottom: 15%;
  left: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  content: "";
  opacity: 0;
  background-color: #ffffff;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

figure span {
  font-size: 30px;
  line-height: 120%;
  font-weight: normal;
  color: white;
  opacity: 0;
  margin: auto;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s, -moz-transform 0.35s,
    -o-transform 0.35s, transform 0.35s;
  justify-content: center;
  align-items: center;
  @include screen-768 {
    font-size: 13px;
  }
  @include screen-992 {
    font-size: 17px;
  }
  @include screen-1200 {
    font-size: 22px;
  }
  @include screen-1600 {
    font-size: 25px;
  }
}

figure span {
  -webkit-transform: translate3d(0%, 50%, 0);
  transform: translate3d(0%, 50%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

figure:hover img {
  opacity: 0.35;
}

figure:hover .photo_projet span {
  opacity: 1;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

figure:hover .photo_projet::before {
  background: rgba(255, 255, 255, 0);
  opacity: 1;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/*-------------------------------------- PROJET DETAIL --------------------------------------*/

.carousel_projet > h3,
.carousel_projet > h4 {
  color: $colorTertiary;
}

.carousel_projet > h3 {
  margin-top: 0;
}

.carousel_projet > h4 {
  padding: 1% 0 0 0;
}

.carousel_projet > p {
  margin-bottom: 0;
}

.infos_techniques {
  font-size: 15px;
  font-style: italic;
  @include screen-1200 {
    font-size: 18px;
  }
}

.projet_detail_slide {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  width: 100vw;
}

.projet_detail {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 1);
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.photo_projet_haut {
  width: auto;
  max-height: 45vh;
  @include screen-576 {
    max-height: 60vh;
  }
  @include screen-992 {
    max-height: 85vh;
  }
}

.projet_photo_long {
  width: 100%;
  height: auto;
  // max-height: 70vh;
  // max-width: 87vw;
  @include screen-1800 {
    width: auto;
  }
}

.projet_detail:target {
  display: none;
}

.carousel-item {
  padding: 0 15%;
}

.carousel_projet {
  text-align: center;
  margin-right: 0;
  backface-visibility: visible;
  transition: none;
}

.carousel_projet_control {
  top: auto;
  bottom: auto;
}

._2MHC_ {
  display: inline-block;
  right: 5%;
  top: 5%;
  cursor: pointer;
  position: fixed;
  z-index: 500;
}

._2MHC_ ._219vN {
  width: 18px;
  height: 18px;
  stroke: #fff;
  stroke-width: 15;
}

.closemsg::after {
  content: "\00D7";
  display: inline-block;
  position: relative;
  right: -20px;
  top: -10px;
  z-index: 3;
  color: #ffffff;
  border: 1px #ffffff solid;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  margin: 0;
  background-color: #000000;
  font-weight: bold;
  cursor: pointer;
}
