.administratif {
  margin-top: 7%;
  h2 {
    margin: 0 0 3% 0;
  }
  p {
    margin-bottom: 13%;
  }
  h2,
  p,
  li {
    text-align: start;
  }
  a {
    color: $colorQuaternary;
    &:hover {
      color: $colorTertiary;
    }
  }
}
