.atelier {
  .image-w100-h100 > img {
    width: 100%;
    height: 100%;
  }

  .img-thumbnail > img {
    width: 100%;
    height: auto;
    @include screen-576 {
      height: 150px;
    }
    @include screen-768 {
      height: 175px;
    }
    @include screen-1200 {
      height: 150px;
    }
    @include screen-1400 {
      height: 200px;
    }
  }

  .titre_page {
    padding: 0 10%;
    @include screen-576 {
      padding: 0 17%;
    }
    @include screen-768 {
      padding: 0;
    }
  }

  .ensemble-services-bloc {
    margin-top: 10%;
  }

  .service-bloc {
    margin: 5%;
    padding: 10%;
    border-radius: 10px;
    @include screen-768 {
      padding: 7%;
    }
    @include screen-1200 {
      margin: 2%;
      padding: 5%;
    }
  }

  .service-bloc > h3 {
    margin-top: 0;
  }

  .service-bloc > p {
    text-align: justify;
  }

  .materiel-margin {
    margin: 10% 2% 0 2%;
  }
}
