/*-------------------------------------- SLIDER --------------------------------------*/

.slider_transition {
  position: relative;
}

.slider {
  display: flex;
  /* contexte sur le parent */
  flex-direction: column;
  /* direction d'affichage verticale */
  justify-content: center;
  /* alignement vertical */
  overflow: hidden;
}

.slider1,
.slider1 > img {
  height: 100%;
  width: 130%;
  margin-left: -15%;
  @include screen-1400 {
    width: 100%;
    margin-left: auto;
  }
}

.logo_slider_base {
  position: absolute;
  width: 100%;
}

.logo_slider {
  z-index: 10;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.transition_naviguation {
  position: absolute;
  width: 100%;
}

.transition_slider {
  position: absolute;
  width: 100%;
  bottom: 0;
}
