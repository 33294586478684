/*-------------------------------------- CONTACT --------------------------------------*/

.contact_index {
  margin-top: 5%;
  margin-bottom: 5%;
}

.bouton_contact {
  font-family: "AppleGaramond", serif;
  font-size: 1rem;
  margin: 2% 0;
  padding: 0.5rem 2rem;
  border-radius: 3rem;
  border-width: 0.1rem;
  color: $colorQuaternaryBis;
  background-color: $colorTertiary;
  @include screen-768 {
    font-size: 1.1rem;
  }
  @include screen-1200 {
    font-size: 1.5rem;
  }
}

.bouton_contact:hover {
  color: $colorQuaternaryBis;
  background-color: transparent;
  border-color: $colorTertiary;
}

.social {
  margin-top: 5%;
}

.fab {
  width: 45px;
  height: 45px;
  border-radius: 100px;
  border-width: 0.1rem;
  background-color: $colorTertiary;
  @include screen-576 {
    font-size: 25px;
    width: 50px;
    height: 50px;
  }
  @include screen-768 {
    font-size: 31px;
    width: 60px;
    height: 60px;
  }
  @include screen-1200 {
    width: 65px;
    height: 65px;
  }
}

.picto {
  @include screen-576 {
    padding: 0 50px;
  }
  @include screen-768 {
    padding: 0;
  }
}

.fab:before {
  vertical-align: -webkit-baseline-middle;
}

.picto:hover {
  border-style: none;
}

.fa-facebook-f,
.fa-instagram {
  width: 21px;
  height: 21px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: translate(52.5%, 52.5%);
  @include screen-576 {
    width: 23px;
    height: 23px;
  }
  @include screen-768 {
    width: 28px;
    height: 28px;
  }
  @include screen-1200 {
    width: 30px;
    height: 30px;
  }
}

.fa-facebook-f {
  background-image: url(../../assets/icons/facebook-f-red.svg);
}

.fa-instagram {
  background-image: url(../../assets/icons/instagram-red.svg);
}

.fab:hover {
  background-color: transparent;
  border-color: $colorTertiary;
}
