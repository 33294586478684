.ombre-interne {
  box-shadow: 0px 1px 25px -15px rgba(0, 0, 0, 1) inset;
  -webkit-box-shadow: 0px 1px 25px -15px rgba(0, 0, 0, 1) inset;
  -moz-box-shadow: 0px 1px 25px -15px rgba(0, 0, 0, 1) inset;
}

.ombre-legere {
  box-shadow: 10px 9px 16px -11px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: 10px 9px 16px -11px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 10px 9px 16px -11px rgba(0, 0, 0, 0.49);
}
