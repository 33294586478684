.contact {
  .infos-contact {
    background-color: $colorSecondary;
    border-radius: 10px;
    margin-top: 75px;
    .photo-contact img {
      width: 100%;
      margin: 0;
      border-radius: 10px;
      overflow: hidden;
      @include screen-992 {
        margin: 30px 0 30px 30px;
        width: auto;
        height: 325px;
        border-radius: 10px;
        overflow: hidden;
      }
      @include screen-1200 {
        height: 350px;
      }
      @include screen-1400 {
        margin: 40px 0 40px 40px;
        height: 385px;
      }
      @include screen-1600 {
        height: 335px;
      }
      @include screen-1800 {
        height: 390px;
      }
      #info-photo-contact {
        height: 100%;
      }
    }
  }
  .formulaire-contact {
    background-color: $colorSecondaryBis;
    border-radius: 10px;
    padding: 1.25rem;
    margin-top: 30px;
    @include screen-768 {
      padding: 2rem;
      margin-top: 50px;
    }
    @include screen-1200 {
      padding: 4rem;
      margin-top: 75px;
    }
    .form-message {
      p {
        border-radius: 20px;
        text-align: center;
        padding: 5px 0;
      }
      .success {
        color: green;
      }
      .error {
        color: red;
      }
    }
    .btnSubmit {
      padding: 12px 25px;
    }
  }
  form {
    .form-control,
    .form-text {
      background-color: $colorSecondary;
      border-color: $colorSecondary;
      padding: 0.75rem 1rem;
      appearance: auto;
      &::placeholder {
        font-size: 1rem;
        color: $colorPrimary;
      }
    }
    .form-text {
      width: 100%;
      margin-top: 0;
      border-radius: 10px;
      padding: 0.375rem 0.75rem;
      font-size: 0.95rem;
    }
    .valid-message {
      margin-bottom: 20px;
    }
  }
  .invalid .feedback {
    color: red;
  }
  .invalid input[type="email"],
  .invalid input[type="number"],
  .invalid input[type="text"],
  .invalid textarea[type="text"],
  .invalid select {
    border-color: red;
  }
  .valid input[type="email"],
  .valid input[type="number"],
  .valid input[type="text"],
  .valid textarea[type="text"],
  .valid select {
    border-color: green;
  }
  .valid .feedback {
    color: green;
  }
}
