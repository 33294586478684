$baseLien: "../../assets/pictures/";

$breakpoints: (
  mobile-350: 350px,
  mobile-370: 370px,
  mobile-400: 400px,
  mobile-576: 576px,
  tablet-768: 768px,
  tablet-992: 992px,
  desktop-1024: 1024px,
  desktop-1200: 1200px,
  desktop-1400: 1400px,
  desktop-1600: 1600px,
  desktop-1800: 1800px,
);

$font-Enchanted: "enchanted", cursive;
$font-AppleGaramond-light: "AppleGaramond-light", serif;
$font-AppleGaramond-light-Italic: "AppleGaramond-light-Italic", serif;
$font-AppleGaramond: "AppleGaramond", serif;
$font-Carolingia: "carolingia", serif;
$font-UnZialish: "UnZialish", serif;

$colorLight: #bfc2c4;
$colorPrimary: #635c51;
$colorSecondary: #e8d3b6;
$colorSecondaryBis: #f2e0c8;
$colorTertiary: #bd8d4c;
$colorTertiaryBis: #6f532f;
$colorQuaternary: #900000;
$colorQuaternaryBis: #640701;
$colorQuinary: #0e0d12;
