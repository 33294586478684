.pages_services {
  .titre_page {
    padding: 0 20%;
    @include screen-576 {
      padding: 0;
    }
  }

  .image-w100-h100 > img {
    width: 100%;
    height: 100%;
  }

  .ensemble-services-bloc {
    margin-top: 10%;
  }

  .service-bloc {
    margin: 5%;
    padding: 10%;
    border-radius: 10px;
    @include screen-768 {
      padding: 7%;
    }
    @include screen-1200 {
      margin: 2%;
      padding: 5%;
    }
  }

  .service-bloc > h3 {
    margin-top: 0;
  }

  .service-bloc > p {
    text-align: justify;
  }

  .types_procedes {
    margin: 20% 0;
    @include screen-576 {
      margin: 10% 0;
    }
    @include screen-992 {
      margin: 20% 0;
    }
  }
  #production_commande:hover {
    background-color: $colorTertiary;
  }
}
