.top-to-btm {
  position: relative;
  z-index: 1;
  @include screen-768 {
    opacity: 0.75;
  }
  .icon-position {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 20;
    @include screen-768 {
      bottom: 30px;
      right: 30px;
    }
  }
  .icon-style {
    background-color: $colorLight;
    border: 2px solid $colorTertiaryBis;
    border-radius: 50%;
    padding: 7px;
    height: 40px;
    width: 40px;
    color: $colorTertiaryBis;
    animation: movebtn 2s ease-in-out;
    transition: all 0.5s ease-in-out;
    &:hover {
      animation: none;
      background: $colorTertiaryBis;
      color: $colorLight;
      border: 2px solid $colorLight;
    }
    @include screen-1200 {
      height: 50px;
      width: 50px;
    }
  }
  @keyframes movebtn {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-50px);
    }
    100% {
      transform: translateY(0px);
    }
  }
}
