@font-face {
  font-family: "enchanted";
  src: url("../../assets/fonts/enchanted_land-webfont.woff2") format("woff2"),
    url("../../assets/fonts/enchanted_land-webfont.woff") format("woff"),
    url("../../assets/fonts/enchanted_land-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AppleGaramond-light";
  src: url("../../assets/fonts/applegaramond-light-webfont.woff2")
      format("woff2"),
    url("../../assets/fonts/applegaramond-light-webfont.woff") format("woff"),
    url("../../assets/fonts/AppleGaramond-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AppleGaramond-light-Italic";
  src: url("../../assets/fonts/applegaramond-lightitalic-webfont.woff2")
      format("woff2"),
    url("../../assets/fonts/applegaramond-lightitalic-webfont.woff")
      format("woff"),
    url("../../assets/fonts/AppleGaramond-LightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AppleGaramond";
  src: url("../../assets/fonts/applegaramond-webfont.woff2") format("woff2"),
    url("../../assets/fonts/applegaramond-webfont.woff") format("woff"),
    url("../../assets/fonts/AppleGaramond.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "carolingia";
  src: url("../../assets/fonts/carolingia.woff2") format("woff2"),
    url("../../assets/fonts/carolingia.woff") format("woff"),
    url("../../assets/fonts/carolingia.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
