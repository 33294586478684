@mixin screen-350 {
  @media screen and (min-width: map-get($breakpoints, "mobile-350")) {
    @content;
  }
}
@mixin screen-370 {
  @media screen and (min-width: map-get($breakpoints, "mobile-370")) {
    @content;
  }
}
@mixin screen-400 {
  @media screen and (min-width: map-get($breakpoints, "mobile-400")) {
    @content;
  }
}

@mixin screen-576 {
  @media screen and (min-width: map-get($breakpoints, "mobile-576")) {
    @content;
  }
}

@mixin screen-768 {
  @media screen and (min-width: map-get($breakpoints, "tablet-768")) {
    @content;
  }
}

@mixin screen-992 {
  @media screen and (min-width: map-get($breakpoints, "tablet-992")) {
    @content;
  }
}

@mixin screen-1024 {
  @media screen and (min-width: map-get($breakpoints, "desktop-1024")) {
    @content;
  }
}

@mixin screen-1200 {
  @media screen and (min-width: map-get($breakpoints, "desktop-1200")) {
    @content;
  }
}

@mixin screen-1400 {
  @media screen and (min-width: map-get($breakpoints, "desktop-1400")) {
    @content;
  }
}

@mixin screen-1600 {
  @media screen and (min-width: map-get($breakpoints, "desktop-1600")) {
    @content;
  }
}

@mixin screen-1800 {
  @media screen and (min-width: map-get($breakpoints, "desktop-1800")) {
    @content;
  }
}

@mixin sp-layout {
  @media screen and (max-width: 750px) {
    @content;
  }
}
