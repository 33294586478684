*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  /* position: relative;
  font-weight: normal; */
}

body {
  background-color: $colorSecondary;
  font-family: "AppleGaramond-light", serif;
  color: $colorPrimary;
}

.container,
.container-md,
.container-sm {
  padding-right: 2%;
  padding-left: 2%;
}

.container {
  @include screen-768 {
    width: 90%;
    padding: 0;
  }
}

.container-fluid,
.container-lg,
.container-xl,
.container-xxl {
  padding: 0;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  @include screen-1200 {
    max-width: 1100px;
  }
  @include screen-1400 {
    max-width: 1300px;
  }
}

.row {
  --bs-gutter-x: 0;
  justify-content: center;
}

img {
  border-radius: 10px;
}

a {
  color: $colorPrimary;
  text-decoration: none;
}

a:hover {
  color: $colorTertiary;
}

.transition_top,
.transition_bottom,
.transition_bottom > img,
.transition_top > img,
.transition_bottom_bis,
.transition_bottom_bis > img {
  height: 100%;
  width: 100%;
  border-radius: 0;
}

.transition_bottom,
.transition_bottom > img {
  vertical-align: top;
}

.transition_bottom_bis,
.transition_bottom_bis > img {
  vertical-align: bottom;
}

.transition_top,
.transition_top > img {
  vertical-align: bottom;
}

picture {
  display: block;
}

.transition_light {
  background-color: $colorSecondaryBis !important;
}

.black {
  color: $colorQuinary;
}

.red {
  color: $colorQuaternary;
}

.orange {
  color: $colorTertiaryBis;
}

.bcolor-sec {
  background-color: $colorSecondary;
}

.bcolor-secbis {
  background-color: $colorSecondaryBis;
}

.gouttiere {
  @include screen-768 {
    margin-top: 0.5rem;
  }
}

.colonne_masque {
  @include screen-992 {
    display: none;
  }
}

.dessin-intro {
  display: none;
  @include screen-992 {
    display: flex;
  }
}

.dessin-intro,
.dessin-intro > img {
  @include screen-992 {
    height: 250px;
    width: auto;
    right: 0;
    bottom: 0;
    position: relative;
    justify-content: end;
  }
  @include screen-1200 {
    height: 300px;
  }
  @include screen-1400 {
    height: 350px;
  }
}

.text-intro {
  @include screen-992 {
    margin-left: 50px;
  }
  @include screen-1400 {
    margin-left: 100px;
  }
  @include screen-1800 {
    margin-left: 200px;
  }
}

.page_contenu .container .ligne_contenu {
  padding: 20% 5%;
  @include screen-768 {
    padding: 15% 0;
  }
}

.ligne_contenu {
  padding: 15% 0;
  @include screen-992 {
    padding: 10% 0;
  }
}

.breadcrumb-item {
  color: $colorTertiary;
  text-shadow: 2px 2px 5px $colorQuinary;
}

.breadcrumb-home,
.breadcrumb-item + .breadcrumb-item::before {
  color: $colorSecondaryBis;
  text-shadow: 2px 2px 5px $colorQuinary;
}

.maillage-interne {
  color: $colorQuaternary;
  font-family: $font-AppleGaramond;

  &:hover {
    color: $colorPrimary;
  }
}
