/*-------------------------------------- FOOTER --------------------------------------*/

footer {
  background-color: $colorQuinary;
}

.site-info {
  margin-top: 3%;
  margin-bottom: 3%;
  color: $colorSecondaryBis;
  text-align: center;
  font-size: 0.75rem;
  @include screen-768 {
    font-size: 1rem;
  }
  @include screen-1200 {
    font-size: 1.3rem;
  }
  a {
    color: $colorSecondaryBis;
    &:hover {
      color: $colorTertiary;
    }
  }
}
