/*-------------------------------------- NAVIGATION --------------------------------------*/

.nav_lg_xl_xxl {
  display: none;
  @include screen-768 {
    display: block;
  }
}

.nav_sm_md {
  @include screen-768 {
    display: none;
  }
}

.titre {
  height: 50px;
  @include screen-576 {
    height: 60px;
  }
  @include screen-768 {
    height: 90px;
  }
}

#navbarNav {
  z-index: 10000;
}

#nav_left,
#nav_right {
  @include screen-992 {
    width: 40%;
  }
  @include screen-1400 {
    width: 30%;
  }
  @include screen-1600 {
    width: 23%;
  }
  @include screen-1800 {
    font-size: 1.45rem;
  }
}

.navbar {
  position: initial;
}

.navbar-collapse {
  align-items: normal;
}

.nav_grand_ft,
.nav_sm_md {
  background-color: $colorQuinary;
}

.nav_petit_ft {
  padding-right: 7%;
  padding-left: 7%;
  @include screen-576 {
    padding-right: 4%;
    padding-left: 4%;
  }
}

.nav_grand_ft {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item {
  @include screen-992 {
    margin: 0 20px;
  }
  @include screen-1400 {
    margin: 0 20px;
  }
  @include screen-1600 {
    margin: 0 20px;
  }
  @include screen-1800 {
    font-size: 1.45rem;
  }
}

.nav-link {
  color: $colorSecondary !important;
  /* text-transform: uppercase !important; */
  font-size: 1.15rem !important;
  font-family: "AppleGaramond", serif;
  @include screen-768 {
    font-size: 1.2rem !important;
  }
  @include screen-992 {
    font-size: 1.3rem !important;
  }
  @include screen-1200 {
    font-size: 1.4rem !important;
  }
  @include screen-1400 {
    font-size: 1.5rem !important;
  }
}

.nav-link:hover {
  color: $colorTertiary !important;
}

.active {
  color: $colorTertiary !important;
  background-color: transparent !important;
}

.navbar-dark .navbar-toggler {
  color: transparent !important;
  border-color: $colorSecondary !important;
}

.navbar-toggler {
  padding: 1% 3%;
  @include screen-576 {
    padding: var(--bs-navbar-toggler-padding-y)
      var(--bs-navbar-toggler-padding-x);
  }
}

.logo,
.logo > img {
  margin-top: 1%;
  height: 100%;
  width: auto;
}

.titre {
  display: inline-block;
}

.bouton_contactMenu {
  font-family: "AppleGaramond", serif;
  font-size: 1.15rem;
  margin: 2% 0 5% 0;
  padding: 0.5rem 1.5rem;
  border-radius: 3rem;
  border-width: 0.1rem;
  border-color: $colorSecondary;
  color: $colorSecondary;
  background-color: $colorQuinary;
  @include screen-768 {
    padding: 0.5rem 1.2rem;
    font-size: 1.2rem;
  }
  @include screen-992 {
    font-size: 1.3rem;
  }
  @include screen-1200 {
    padding: 0.5rem 2rem;
    font-size: 1.4rem;
  }
  @include screen-1400 {
    font-size: 1.5rem;
  }
}

.bouton_contactMenu:hover {
  background-color: $colorTertiary;
  color: $colorQuaternaryBis;
}

/*-------------------------------------- SOUS MENU --------------------------------------*/

.sous {
  display: none;
  background-color: $colorQuinary;
  width: auto;
  border-radius: 10px;
}

.sous_GD_FT {
  padding: 0 1.5% 1.5% 1.5%;
  position: absolute;
  z-index: 10000;
}

nav > ul li:hover .sous {
  display: block;
}

.sous li {
  float: none;
  text-align: left;
}

.sous a {
  padding: 10px;
  border-bottom: none;
}

.sous a:hover {
  border-bottom: none;
}

li::marker {
  display: none;
  color: $colorQuinary;
}
