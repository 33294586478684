/*-------------------------------------- PRESENTATION --------------------------------------*/

.presentation {
  margin-top: 50px;
  background-image: url(../../assets/images/logos_dessins/dessin_dragon.webp);
  background-position: 120% 100%;
  background-repeat: no-repeat;
  padding-bottom: 200px;
  @include screen-576 {
    margin-top: 50px;
    background-position: 120% 100%;
    background-size: 60%;
    padding-bottom: 250px;
  }
  @include screen-768 {
    margin-top: 100px;
    background-position: 120% 100%;
    background-size: 55%;
    padding-bottom: 300px;
  }
  @include screen-992 {
    margin-top: 125px;
    background-position: 110% 100%;
    background-size: 45%;
    padding-bottom: 350px;
  }
  @include screen-1200 {
    background-position: 105% 110%;
    background-size: 40%;
    padding-bottom: 300px;
  }
  @include screen-1400 {
    margin-top: 150px;
    background-position: 2% 110%;
    background-size: 30%;
    padding-bottom: 200px;
  }
  @include screen-1600 {
    margin-top: 175px;
  }
  @include screen-1800 {
    margin-top: 200px;
    background-position: 1% 100%;
    background-size: 25%;
    padding-bottom: 275px;
  }
}

#presentation {
  width: 100%;
  position: relative;
  overflow: hidden;
  @include screen-992 {
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#presentation > img {
  height: 100%;
  width: 100%;
  @include screen-992 {
    width: auto;
  }
}

.presentation_before {
  background-color: $colorSecondaryBis;
  border-radius: 10px;
  padding: 2em;
  width: 85%;
  margin: auto;
  @include screen-576 {
    --bs-gutter-x: 1.5rem;
    padding: 2em 0;
  }
  @include screen-768 {
    right: 30%;
    left: -20px;
  }
  @include screen-992 {
    width: 90%;
  }
  @include screen-1600 {
    width: 85%;
  }
  @include screen-1800 {
    width: 100%;
  }
}

.photo_presentation {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  @include screen-992 {
    margin: -50px -50px 0 0;
    width: auto;
    height: 600px;
    border-radius: 10px;
    overflow: hidden;
  }
  @include screen-1200 {
    height: 610px;
  }
  @include screen-1400 {
    margin: -60px -60px 0 0;
    height: 655px;
  }
  @include screen-1800 {
    height: 700px;
  }
}

/*-------------------------------------- QUELQUES OEUVRES... --------------------------------------*/

.photos_ouvrages {
  background-color: $colorSecondaryBis;
}

.realisation_before {
  @include screen-1200 {
    --bs-gutter-x: 3rem !important;
  }
}

.realisation {
  margin-top: 15%;
  margin-bottom: 15%;
  @include screen-1400 {
    margin-top: 10%;
    margin-bottom: 10%;
  }
}

.oeuvres {
  color: #fff;
  position: relative;
  overflow: hidden;
  background: #000000;
  text-align: center;
  margin: 10%;
  border-radius: 10px;
  @include screen-992 {
    margin: 4%;
  }
  @include screen-1200 {
    margin: 2%;
  }
}

.photo_exemple,
.photo_exemple > img {
  width: 100%;
  height: 100%;
}

.oeuvres_marges {
  margin: 15px;
  width: 65%;
  @include screen-576 {
    margin: 20px;
    width: 75%;
  }
  @include screen-768 {
    margin: 20px 0;
    width: 33.3%;
  }
}

.oeuvres img {
  opacity: 1;
  width: 100%;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.oeuvres:hover img {
  opacity: 0.35;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.oeuvres span {
  font-size: 30px;
  line-height: 120%;
  font-weight: normal;
  opacity: 0;
  margin: auto;
}

.oeuvres:hover .photo_projet span {
  opacity: 1;
  -webkit-transition: opacity 0.75s;
  transition: opacity 0.75s;
}

.oeuvres .photo_projet::before {
  position: absolute;
  top: 15%;
  right: 20px;
  bottom: 15%;
  left: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  content: "";
  opacity: 0;
}

.oeuvres:hover .photo_projet::before {
  background: rgba(255, 255, 255, 0);
  opacity: 1;
  -webkit-transition: opacity 0.75s;
  transition: opacity 0.75s;
}

.bouton_realisation {
  font-family: "AppleGaramond", serif;
  font-size: 1rem;
  margin: 2% 0 5% 0;
  padding: 0.5rem 2rem;
  border-radius: 3rem;
  border-width: 0.1rem;
  color: $colorQuaternaryBis;
  background-color: $colorTertiary;
  @include screen-768 {
    font-size: 1.1rem;
  }
  @include screen-1200 {
    font-size: 1.5rem;
  }
}

.bouton_realisation:hover {
  color: $colorQuaternary;
  background-color: transparent;
  border-color: $colorTertiary;
}

/*-------------------------------------- SAVOIR-FAIRE... --------------------------------------*/

.savoir-faire {
  margin-top: 50px;
  background-image: url(../../assets/images/logos_dessins/dessin_village.webp);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 150px;
  @include screen-576 {
    margin-top: 50px;
    background-position: 120% 100%;
    background-size: 60%;
    padding-bottom: 250px;
  }
  @include screen-768 {
    margin-top: 100px;
    background-position: 120% 100%;
    background-size: 55%;
    padding-bottom: 300px;
  }
  @include screen-992 {
    margin-top: 125px;
    background-position: 110% 100%;
    background-size: 45%;
    padding-bottom: 350px;
  }
  @include screen-1200 {
    background-position: 105% 110%;
    background-size: 40%;
    padding-bottom: 300px;
  }
  @include screen-1400 {
    margin-top: 150px;
    background-position: 100% 110%;
    background-size: 30%;
    padding-bottom: 200px;
  }
  @include screen-1600 {
    margin-top: 175px;
  }
  @include screen-1800 {
    margin-top: 200px;
    background-size: 25%;
    padding-bottom: 275px;
  }
}

#savoir-faire {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  @include screen-992 {
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.savoir-faire_before {
  background-color: $colorSecondaryBis;
  border-radius: 10px;
  padding: 2em;
  width: 85%;
  margin: auto;
  @include screen-576 {
    --bs-gutter-x: 1.5rem;
    padding: 2em 0;
  }
  @include screen-768 {
    right: 30%;
    left: -20px;
  }
  @include screen-1200 {
    width: 75%;
  }
  @include screen-1600 {
    width: 85%;
  }
  @include screen-1800 {
    width: 100%;
  }
}

#savoir-faire > img {
  height: 100%;
  width: 100%;
  @include screen-992 {
    width: auto;
  }
}

.photo_savoir-faire {
  width: 100%;
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
  @include screen-992 {
    margin: -50px 0 0 -50px;
    width: auto;
    height: 470px;
  }
  @include screen-1200 {
    height: 500px;
  }
  @include screen-1400 {
    margin: -60px 0 0 -60px;
    height: 535px;
  }
  @include screen-1600 {
    height: 515px;
  }
  @include screen-1800 {
    height: 540px;
  }
}

.bouton_savoir-faire {
  font-family: "AppleGaramond", serif;
  font-size: 1rem;
  margin: 2% 0 5% 0;
  padding: 0.5rem 2rem;
  border-radius: 3rem;
  border-width: 0.1rem;
  color: $colorQuaternaryBis;
  background-color: $colorTertiary;
  @include screen-768 {
    padding: 0.5rem 1.2rem;
    font-size: 1.1rem;
  }
  @include screen-1200 {
    font-size: 1.25rem;
  }
  @include screen-1400 {
    padding: 0.5rem 2rem;
  }
  @include screen-1800 {
    font-size: 1.45rem;
  }
}

.bouton_savoir-faire:hover {
  color: $colorQuaternary;
  background-color: transparent;
  border-color: $colorTertiary;
}
